export const menuItems = [
  {
    title: "Domov",
    url: "/",
  },
  {
    title: "O NÁS",
    url: "/o-nas",
  },
  {
    title: "Kurzy",
    submenu: [
      {
        title: "Anglický jazyk",
        url: "/kurzy/anglicky-jazyk",
      },
      {
        title: "Španielsky jazyk",
        url: "/kurzy/spanielsky-jazyk",
      },
      // {
      //   title: "Prípravný kurz",
      //   url: "/kurzy/pripravny-kurz",
      // },
    ],
  },
  {
    title: "Galéria",
    url: "/galeria",
  },
  {
    title: "Registrácia",
    url: "/registracia",
  },
  {
    title: "Letný tábor",
    url: "/letny-tabor",
  },
];
